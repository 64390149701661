import { AnyAction } from 'redux';
import { Auth, AuthState } from '../../Components/Entities/login.model';

export const authState: AuthState = {
  authenticated: false,
  token: '',  
  remember: {
    username: '',
    status: false,
  },
};

export function authReducer(
  state: AuthState = authState,
  action: AnyAction,
): AuthState {
  switch (action.type) {
    case 'USERS_LOGIN_SUCCESS':
      return {
        ...state,
        authenticated: true,
        token: action.data,
      };
    case 'USERS_LOGOUT':
      return {
        ...state,
        token: undefined,
        authenticated: false,
      };    
    default:
      return state;
  }
}
