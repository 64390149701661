import CryptoJS from 'crypto-js';
import { combineReducers } from 'redux';
import { createTransform } from 'redux-persist';
import reducers from './Reducers/reducers';

let rootReducer = (state: any, action: any) => {
  return combineReducers(reducers)(state, action);
};

const encrypt = createTransform(
  (inboundState: string, key) => {
    if (!inboundState) return inboundState;
    const cryptedText = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      'ENCRYPTED_REDUX',
    );

    return cryptedText.toString();
  },
  (outboundState: string, key) => {
    if (!outboundState) return outboundState;
    const bytes = CryptoJS.AES.decrypt(outboundState, 'ENCRYPTED_REDUX');
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  },
);

const persistConfig = {
  // Storage Method (React Native)
  transforms: [encrypt],
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'auth',
    'device',
    'account',
    'confidential',
    'language',
    'card',
    'main',
    //'transaction',
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
};

export { persistConfig, rootReducer };
