import { Layout } from 'antd';
import React from 'react';
import FooterCustom from './FooterCustom/FooterCustom';
import PrivateAppHeader from './PrivateAppHeader';
import PrivateMenu from '../../Components/Layout/PrivateMenu';

export default (props: any) => {

  console.log('render Main Admin')
  const { Sider, Content } = Layout;
  return (
    <Layout className="app-container">
      <PrivateAppHeader />
      <Layout>
        <Sider width="212" className="app-aside">
          <PrivateMenu />
        </Sider>
        <Content className="app-content">
          <div>
            {props.children}
          </div>
          {/* <Route path="/" exact component={PrivateRoutes} /> */}
          <FooterCustom></FooterCustom>
        </Content>
      </Layout>
      {/* <AppModal /> */}
    </Layout>
  )
}

