import { persistConfig, rootReducer } from './config';
import { applyMiddleware, legacy_createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

const rootConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage: storage,
  ...persistConfig,
};

const persistedReducer = persistReducer(rootConfig, rootReducer);

const store = legacy_createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware),
  // applyMiddleware(thunkMiddleware, loggerMiddleware),
);

const persistor = persistStore(store);

export { store, persistor };
