import { AnyAction } from 'redux';
import { RequestState } from '../../Components/Entities/request.model';


export const initialState: RequestState = {
  loading: false, 
};

export function requestReducer(
  state: RequestState = initialState,
  action: AnyAction,
): RequestState {
  switch (action.type) {
    case 'BLOCK': 
      return {
         ...state,
        loading: true,
      }
    case 'UNBLOCK': 
      return {
         ...state,
        loading: false,
      }
    case 'SET_REQFORM_VALUES':  {
        return {
          ...state,
          formValues: action.data,
        }
      }
    case 'SET_RESULT_VALUES': {
        return {
          ...state,
          result: action.data,
        };
      }
    default:
        return state;
    }
  }

