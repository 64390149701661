import { Badge, Layout, Tooltip, Drawer, Popover, Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateMenu from './PrivateMenu';
import { MenuOutlined } from '@ant-design/icons'

const { Header } = Layout;

function PrivateAppHeader() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [badgeTrue, setBadgeTrue] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const onClose = () => {
    setDrawerVisible(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  return (
    <>
      <Header className="app-header">
        <div className="logo">
          <Link to="/home">
            <img
              src={'/public/images/org_logo_leter.png'}
              className="logo"
              width='15'
              height='15'
            />
          </Link>
        </div>
        <div className="top-tools">
          <ul className="mobile-tools">
          {/* <li className="nt">
              <Popover
                placement="bottom"
                // content={<Notification mails={mails} />}
                trigger="hover"
              >
                <a
                  className="button-circle withNotification"
                // onClick={() =>
                //   navigationService.navigate('/mails/', { item: mails })
                // }
                >
                  <NotificationOutlined />
                  <Badge dot={badgeTrue} />
                </a>
              </Popover>
            </li> */}
            <li>
            <Link to="/login">
                {t('btn.h_Logout')}
              </Link>

            </li>
            <li>
              <a className="primary" onClick={() => showDrawer()}>
              <MenuOutlined />
              </a>
            </li>
          </ul>

          <ul className={'tools-items'}>
            {/* <li className="nt">
              <Popover
                placement="bottom"
                // content={<Notification mails={mails} />}
                trigger="hover"
              >
                <a
                  className="button-circle withNotification"
                // onClick={() =>
                //   navigationService.navigate('/mails/', { item: mails })
                // }
                >
                  <NotificationOutlined />
                  <Badge dot={badgeTrue} />
                </a>
              </Popover>
            </li> */}
            <li className="kb-name">
              <Link to="/login">
                {t('btn.h_Logout')}
              </Link></li>

              <Tooltip title={t('btn.h_Logout')}>
                <li className="lo">

                </li>
              </Tooltip>
            </ul>
        </div>
      </Header>

      <Drawer
        placement="left"
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <PrivateMenu onPress={(path: string) => onClose()} />
      </Drawer>
    </>
  );
}

export default PrivateAppHeader;
