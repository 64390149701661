import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import PrivateRoutes from "../Components/Layout/PrivateLayout";
import PublicRoutes from "../Components/Layout/PublicLayout";

import './Public/styles.less';
import '../resources/styles/styles.less';
import '../resources/styles/_normalize.less';
import '../resources/styles/_content.less';


const HomePage = React.lazy(() => import('./HomePage/Index'));
const Login = React.lazy(() => import('./Private/Auth/Login/Login'));
const ForgotPassword = React.lazy(() => import('./Private/Auth/ForgotPassword/ForgotPassword'));
const ChangePassword = React.lazy(() => import('./Private/Auth/ChangePassword/ChangePassword'));
const CustInfo = React.lazy(() => import('./Private/Auth/CustInfo'));
const RequestPrivate = React.lazy(() => import('./Private/Request/Request'));
const RequestConfirmationPrivate = React.lazy(() => import('./Private/RequestConfirmation/RequestConfirmation'));
const Request = React.lazy(() => import('./Public/Request/Request'));
const RequestConfirmation = React.lazy(() => import('./Public/RequestConfirmation/RequestConfirmation'));
const Statement = React.lazy(() => import('./Private/Statement/Statement'));
const SignUp = React.lazy(() => import('./Public/SignUp'));


class route extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <Switch>

        <Route exact path="/homepage">
          <Redirect to="/" />
        </Route>

        <Route path='/' exact component={HomePage} />
        <Route exact path="/Login">
          <Login />
        </Route>
        <Route exact path="/Forgotpassword">
          <ForgotPassword />
        </Route>

        <Route path='/private/:path?' exact>
          <PrivateRoutes>
            <Switch>
              <Route path='/private/request' component={RequestPrivate} />
              <Route path='/private/RequestConfirmation' component={RequestConfirmationPrivate} />
              <Route path='/private/Statement' component={Statement} />
              <Route path='/private/CustInfo' component={CustInfo} />
              <Route path='/private/ChangePassword' component={ChangePassword} />
            </Switch>
          </PrivateRoutes>
        </Route>

        <Route path='/PerformTransfer/:path?' exact>
          <PublicRoutes>
            <Switch>
              <Route path='/PerformTransfer/DoTransfer' component={Request} />
            </Switch>
          </PublicRoutes>
        </Route>



        <Route path='/pblc/:path?' exact>
          <PublicRoutes>
            <Switch>
              <Route path='/pblc/request' component={Request} />
              <Route path='/pblc/RequestConfirmation' component={RequestConfirmation} />
              <Route path='/pblc/signup' component={SignUp} />
            </Switch>
          </PublicRoutes>
        </Route>

        <Route path="*" exact={true}>
          <Redirect to="/404" />
        </Route>
      </Switch>
    );
  }

}

export default route;

