import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
interface PrivateMenuProps {
  onPress?: (path: string) => void;
}

export const Web: {
  menuId: string;
  icon: string;
  menuText: string;
  menuTextMn: string;
  menuIdOrder: number;
  promotionalFlag: string;
  userAcceptanceFlag: string;
  exact: boolean;
}[] = [
  {
    menuId: 'Transfer',
    icon: 'icon-change',
    menuText: '/private/Statement',
    menuTextMn: 'txt.m_RequestHistory',
    menuIdOrder: 3,
    promotionalFlag: 'N',
    userAcceptanceFlag: 'N',
    exact: false,
  },
  {
    menuId: 'Home',
    icon: 'icon-homeMain',
    menuText: '/private/request',
    menuTextMn: 'txt.m_Request',
    menuIdOrder: 1,
    promotionalFlag: 'N',
    userAcceptanceFlag: 'N',
    exact: true,
  },
  {
    menuId: 'Account',
    icon: 'icon-savingsFilled',
    menuText: '/private/CustInfo',
    menuTextMn: 'txt.m_Senderinfo',
    menuIdOrder: 2,
    promotionalFlag: 'N',
    userAcceptanceFlag: 'N',
    exact: true,
  },
  {
    menuId: 'Account',
    icon: 'icon-savingsFilled',
    menuText: '/private/ChangePassword',
    menuTextMn: 'txt.m_ChangePassword',
    menuIdOrder: 2,
    promotionalFlag: 'N',
    userAcceptanceFlag: 'N',
    exact: true,
  },
];
// class PrivateMenu extends React.Component<PrivateMenuProps> {
function PrivateMenu(props: PrivateMenuProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workingMenus = [
    'Account',
    'Home',
    'Transfer',
    'Cart',
    'Payment',
    'Service',
    'Loan',
  ];

  return (
    <ul className="app-menu">
      {Web.map((item, index) => {
        if (workingMenus.find(x => x === item.menuId) === undefined) {
          return (
            <li key={index}>
              <a
                className="menu-item"
                onClick={() => {
                  if (workingMenus.find(x => x === item.menuId) === undefined) {
                    // ShowSuccessModal(false, t('Coming soon.'), () => {});
                    return;
                  } else {
                    // navigationService.navigate(item.menuText);
                  }
                }}
              >
                <i className={item.icon} />
                <span>{t(item.menuTextMn)}</span>
              </a>
            </li>
          );
        } else {
          return (
            <li key={index}>
              <NavLink
                to={item.menuText}
                className="menu-item"
                onClick={() => {
                  if (item.menuId === 'Transfer') {
                    //dispatch(transactionAction.resetTransaction());
                  }

                  if (!props.onPress) return;

                  props.onPress(item.menuText);
                }}
              >
                <i className={item.icon} />
                <span>{t(item.menuTextMn)}</span>
              </NavLink>
            </li>
          );
        }
      })}
    </ul>
  );
}

export default PrivateMenu;
