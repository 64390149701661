import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { AuthState,LanguageState } from '../../Components/Entities/login.model';
import { CustomerState } from '../../Components/Entities/customer.model';
import { TransactionState } from '../../Components/Entities/transaction.model';
import { RequestState } from '../../Components/Entities/request.model';
import { authReducer } from './auth.reducer';
import { languageReducer } from './language.reducer';
import { customerReducer } from './customer.reducer';
import { transactionReducer } from './transaction.reducer';
import { requestReducer } from './request.reducer';

export default  {
  // ...reducers,
  // ...dashboard,
  // ...account,
  auth: authReducer,
  language: languageReducer,
  customer: customerReducer,
  transaction: transactionReducer,
  request: requestReducer,
};


export interface RootState {
  auth: AuthState;
  // reset: ResetState;
  // device: DeviceVerifyState;
  language:  LanguageState 
  customer: CustomerState;
  transaction: TransactionState;
  request: RequestState;
  // register: OnlineRegister;
  // confidential: ConfidentialState;
  // menu: AllMenu;
  // account: AccountState;0
  // main: MainState;


  // card: CardState;
  // split: SplitState;
  // tax: TaxState;
}





export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
