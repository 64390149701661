import { Layout } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../../package.json';
import './FooterCustom.less';

const { Footer } = Layout;
function FooterCustom() {
  const { t } = useTranslation();

  return (
    <Footer className="footer">
      <span>
        {t('txt.m_copyrights').replace(
          '{year}',
          moment()
            .year()
            .toString(),
        )}
      </span> 
    </Footer>
  );
}

export default FooterCustom;
