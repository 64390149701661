import { AnyAction } from 'redux';
import { TransactionState } from '../../Components/Entities/transaction.model';


export const initialState: TransactionState = {
  loading: false, 
 
};

export function transactionReducer(
  state: TransactionState = initialState,
  action: AnyAction,
): TransactionState {
  switch (action.type) {
    case 'BLOCK': 
      return {
        // ...state,
        loading: true,
      }
    case 'UNBLOCK': 
      return {
        // ...state,
        loading: false,
      }
    case 'SET_FORM_VALUES': 
        return {
          ...state,
          formValues: action.data,
        };
      
      default:
        return state;
    }
  }

