import { Badge, Layout, Tooltip, Drawer, Popover, Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PublicMenu from './PublicMenu';
import { NotificationOutlined, MenuOutlined  } from '@ant-design/icons'
import { Mails } from '../Entities/mails.models';
import Notification from '../Common/Notification/Notification';
import { navigationService } from '../../providers/navigation';




const { Header } = Layout;

function PublicAppHeader() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [badgeTrue, setBadgeTrue] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [mails, setMails] = useState<Mails[]>();

  const onClose = () => {
    setDrawerVisible(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  return (
    <>
      <Header className="app-header">
        <div className="logo">
          <Link to="/home">
            <img
              src={'/public/images/org_logo_leter.png'}
              className="logo"
              width='15'
              height='15'
            />

            {/* <Button
                    className="dm-button"
                    htmlType="submit"
                    type="primary"
                    // style={{ marginTop: 24 }}
                    icon={<SaveOutlined />}
                  // onClick={() => nextStep()}
                  >
                    {t('btn.b_logIn')}
                  </Button> */}
          </Link>
        </div>
        <div className="top-tools">
          <ul className="mobile-tools">
          {/* <li className="nt">
              <Popover
                placement="bottom"
                // content={<Notification mails={mails} />}
                trigger="hover"
              >
                <a
                  className="button-circle withNotification"
                // onClick={() =>
                //   navigationService.navigate('/mails/', { item: mails })
                // }
                >
                  <NotificationOutlined />
                  <Badge dot={badgeTrue} />
                </a>
              </Popover>
            </li> */}
            <li>
              <Link to="/login">
                {t('btn.b_logIn')}
              </Link></li>
            <li>
              <a className="primary" onClick={() => showDrawer()}>
                <MenuOutlined />
              </a>
            </li>
          </ul>

          <ul className={'tools-items'}>
            {/* <li className="nt">
              <Popover
                placement="bottom"
                // content={<Notification mails={mails} />}
                trigger="hover"
              >
                <a
                  className="button-circle withNotification"
                // onClick={() =>
                //   navigationService.navigate('/mails/', { item: mails })
                // }
                >
                  <NotificationOutlined />
                  <Badge dot={badgeTrue} />
                </a>
              </Popover>
            </li> */}

            <li className="kb-name">
              <Link to="/login">
                {t('btn.b_logIn')}
              </Link></li>

            <Tooltip title={t('btn.h_Logout')}>
              <li className="lo">

              </li>
            </Tooltip>
          </ul>
        </div>
      </Header>

      <Drawer
        placement="left"
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <PublicMenu  onPress={(path: string) => onClose()}/>
      </Drawer>
    </>
  );
}

export default PublicAppHeader;
