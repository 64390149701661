import React, { Component,Suspense, useState, useEffect } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
// import {InputAmount } from "./Modules/InputAmount";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from "./Modules/HomePage/Index";
import Footer from "./Modules/Footer";
import { createBrowserHistory } from 'history';
import { SkeletonTheme } from 'react-loading-skeleton';

import { Layout, message, Spin } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './providers/store';

import Cookies from 'js-cookie'
import Login from './Modules/Private/Auth/Login/Login'
import { AuthContext } from "./context/Auth"
import PrivateLayout from "./Components/Layout/PrivateLayout"
import PublicLayout from "./Components/Layout/PublicLayout"
import Routers from "./Modules/route"
import { useTypedSelector } from './providers/Reducers/reducers';
export const history = createBrowserHistory({ basename: '/' });

const Loader = () => (
  <div className="App">
    {/* <img src={logo} className="App-logo" alt="logo" /> */}
    <div>loading...</div>
  </div>
);


function App() {
  const [loggedin, setLoggedin] = useState(false)
  const [second, setSecond] = useState(false)
  const state = useTypedSelector(state => state);
  
  useEffect(() => {

    setSecond(true)
    let sstoken = Cookies.get('token')
    if (sstoken !== null && sstoken !== undefined) {
      setLoggedin(true)
    }
  }, [loggedin])

  useEffect(() => {
    if (!navigator.onLine)
      message.error(
        'Интернэт холболтоо шалгана уу.',
        0,
      );
    else message.destroy();
  }, [navigator.onLine]);

  
  function loggedinupdate() {
    setLoggedin(true)
  }

  return (
    <SkeletonTheme baseColor="#fbfbfb" highlightColor="#D1D0CE">
      <Layout>
        <PersistGate
          loading={<Spin className="kb-screen-center"></Spin>}
          persistor={persistor}
        >
          <Router history={history}>
            <Suspense fallback={<Spin className="kb-screen-center"></Spin>}>
              <Switch>
                <Routers />
              </Switch>
            </Suspense>
          </Router>
        </PersistGate>
      </Layout>
    </SkeletonTheme>
  );

}
export default App;
