import { AnyAction } from 'redux';
import { CustomerState } from '../../Components/Entities/customer.model';


export const initialState: CustomerState = {
  loading: false, 
};

export function customerReducer(
  state: CustomerState = initialState,
  action: AnyAction,
): CustomerState {
  switch (action.type) {   
    case 'SET_CUSTOMER_VALUES': {
        return {
          ...state,
          formValues: action.data,
        };
      }
      default:
        return state;
    }
  }

