import { AnyAction } from 'redux';
import { LanguageState } from '../../Components/Entities/login.model';


const initialState: LanguageState = {
  language: 'en',
};

export function languageReducer(
  state: LanguageState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        language: action.data,
      };
    default: {
      return state;
    }
  }
}
