import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { DateTime } from 'luxon';
import translation_en from "./resources/lang/en/transaction.json";
import translation_mn from "./resources/lang/mn/transaction.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "mn",
    debug: true,
    lng: "mn",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    returnObjects: true,
    resources: {
      en: {
          translation: translation_en               // 'common' is our custom namespace
      },
      mn: {
        translation: translation_mn
      },
  },
  });

i18n.services.formatter.add('DATE_LONG', (value, lng, _options) => {
  return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE)
});

export default i18n;